import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);



const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/research-and-work-experience",
    name: "Research and Work Experience",
    // route level code-splitting
    // this generates a separate chunk (cv.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cv" */ "@/views/WorkExperience.vue"),
  },
  {
    path: "/projects",
    name: "Projects",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/projects/Projects.vue"
      ),
  },
  {
    path: "/projects/hydroponic-harvesting-robot",
    name: "Hydroponic Harvesting Robot",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/projects/HydroponicHarvestingRobot.vue"
      ),
  },
  {
    path: "/projects/cube-collection-robot",
    name: "Cube Collection Robot",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/projects/CubeCollectionRobot.vue"
      ),
  },
  {
    path: "/projects/inverted-pendulum-robot",
    name: "Inverted Pendulum Robot",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/projects/InvertedPendulumRobot.vue"
      ),
  },
  {
    path: "/projects/nlp-robot-arm",
    name: "Natural Language Processing Assisted Robot Arm",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/projects/NLPRobotArm.vue"
      ),
  },
  {
    path: "/projects/rrt",
    name: "3D RRT Planner",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/projects/RRT.vue"
      ),
  },
  {
    path: "/projects/html-handbook",
    name: "HTML Handbook",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/projects/HTMLHandbook.vue"
      ),
  },
  {
    path: "/projects/skitter-robot",
    name: "Skitter Robot",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/projects/SkitterRobot.vue"
      ),
  },
  {
    path: "/projects/robot-arm",
    name: "Intelligent Robot Arm",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/projects/RobotArm.vue"
      ),
  },
  {
    path: "/projects/lego-cnn",
    name: "Lego Classification CNN",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/projects/LegoCNN.vue"
      ),
  },
  {
    path: "/projects/panorama",
    name: "Panoramic Image Generator",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "@/views/projects/Panorama.vue"
      ),
  },
  {
    path: "/courses",
    name: "Courses",
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/Courses.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
